import { Card, Descriptions, Divider, List, Button, Tag, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { Dish, Order, OrderDish, OrderStatus, User } from "../../models";

const statusToColor = {
  [OrderStatus.COMPLETADO]: "green",
  [OrderStatus.EN_PROCESO]: "orange",
  [OrderStatus.RECHAZADO]: "red",
  [OrderStatus.EN_CAMINO]: "purple",
};

const DetailedOrder = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [dishes, setDishes] = useState([]);
  const [platillos, setPlatillos] = useState([]);

  const getOrder = async (id) => {
    const order = await DataStore.query(Order, id);
    const orderDishes = await DataStore.query(OrderDish, (od) =>
      od.orderID("eq", id)
    );

    return { ...order, dishes: orderDishes };
  };

  useEffect(() => {
    if (!id) return;
    getOrder(id).then(setPlatillos);
  }, [id]);

  console.log(platillos);

  useEffect(() => {
    DataStore.query(Order, id).then(setOrder);
  }, [id]);

  useEffect(() => {
    if (order?.userID) {
      DataStore.query(User, order.userID).then(setCustomer);
    }
  }, [order?.userID]);

  useEffect(() => {
    if (!order?.id) {
      return;
    }

    DataStore.query(OrderDish, (c) => c.orderID("eq", order.id)).then(
      setDishes
    );
  }, [order?.id]);

  const acceptOrder = async () => {
    updateOrderStatus(OrderStatus.EN_PROCESO);
  };

  const declineOrder = async () => {
    updateOrderStatus(OrderStatus.RECHAZADO);
  };

  const readyForPickup = async () => {
    const user = await DataStore.save(
      User.copyOf(customer, (updated) => {
        updated.pedidoActivo = "false";
      })
    );
    setCustomer(user);
    updateOrderStatus(OrderStatus.COMPLETADO);
  };

  const updateOrderStatus = async (newStatus) => {
    const updatedOrder = await DataStore.save(
      Order.copyOf(order, (updated) => {
        updated.status = newStatus;
      })
    );
    setOrder(updatedOrder);
  };

  if (!order) {
    return <Spin size="large" />;
  }

  return (
    <Card title={`Order ${id}`} style={{ margin: 20 }}>
      <Tag color={statusToColor[order?.status]}>{order?.status}</Tag>
      <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}>
        <Descriptions.Item label="Nombre">{customer?.name}</Descriptions.Item>
        <Descriptions.Item label="Apellido">
          {customer?.lastname}
        </Descriptions.Item>
        <Descriptions.Item label="Palco">{customer?.palco}</Descriptions.Item>
        <Descriptions.Item label="Metodo de pago">
          {order?.tipoPago}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <List
        dataSource={dishes}
        renderItem={(dishItem) => (
          console.log(dishItem),
          (
            <List.Item>
              <div style={{ fontWeight: "bold" }}>
                {dishItem?.Dish?.name} - {dishItem.comentario}
              </div>
              <div>$ {dishItem?.Dish?.price}</div>
            </List.Item>
          )
        )}
      />
      <Divider />
      <div style={styles.totalSumContainer}>
        <h2>Total:</h2>
        <h2 style={styles.totalPrice}>${order?.total?.toFixed(2)}</h2>
      </div>
      <Divider />
      {order?.status === OrderStatus.NUEVO && (
        <div style={styles.buttonsContainer}>
          <Button
            block
            type="danger"
            size="large"
            style={styles.button}
            onClick={declineOrder}
          >
            Decline Order
          </Button>
          <Button
            block
            type="primary"
            size="large"
            style={styles.button}
            onClick={acceptOrder}
          >
            Accept Order
          </Button>
        </div>
      )}
      {order.status === OrderStatus.EN_PROCESO && (
        <Button
          block
          type="primary"
          size="large"
          onClick={() => readyForPickup()}
        >
          Food Is Done
        </Button>
      )}
    </Card>
  );
};

const styles = {
  totalSumContainer: {
    flexDirection: "row",
    display: "flex",
  },
  totalPrice: {
    marginLeft: "auto",
    fontWeight: "bold",
  },
  buttonsContainer: {
    display: "flex",
    paddingBottom: 30,
  },
  button: {
    marginRight: 20,
    marginLeft: 20,
  },
};

export default DetailedOrder;
