import { createContext, useState, useEffect, useContext } from "react";
import { Auth, DataStore, Hub } from "aws-amplify";
import { Restaurant } from "../models";

const RestaurantContext = createContext({});

const RestaurantContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [sub, setSub] = useState(null);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const users = await Auth.currentAuthenticatedUser();
      setUser(users);
      setSub(user.attributes.sub);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // Create listener that will stop observing the model once the sync process is done
    const removeListener = Hub.listen("datastore", async (capsule) => {
      const {
        payload: { event, data },
      } = capsule;

      console.log("DataStore event", event, data);

      if (event === "ready") {
        const restaurants = await DataStore.query(Restaurant);

        setRestaurant(restaurants);
      }
    });

    // Start the DataStore, this kicks-off the sync process.
    DataStore.start();

    return () => {
      removeListener();
    };
  }, []);

  return (
    <RestaurantContext.Provider value={{ restaurant, setRestaurant, sub }}>
      {children}
    </RestaurantContext.Provider>
  );
};

export default RestaurantContextProvider;

export const useRestaurantContext = () => useContext(RestaurantContext);
