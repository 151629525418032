import React, { useEffect, useState } from "react";
import { Form, Input, Card, Button, message, TimePicker } from "antd";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { DataStore } from "aws-amplify";
import { Restaurant } from "../../models";
import { useRestaurantContext } from "../../contexts/RestaurantContext";
import moment from "moment";

const format = "HH:mm";

const Settings = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [timezone, setTimezone] = useState([""]);

  const { sub, restaurant, setRestaurant } = useRestaurantContext();

  useEffect(() => {
    if (restaurant) {
      setName(restaurant.name);
      setCoordinates({ lat: restaurant.lat, lng: restaurant.lng });
    }
  }, [restaurant]);

  const getAddressLatLng = async (address) => {
    setAddress(address);
    const geocodedByAddress = await geocodeByAddress(address.label);
    const latLng = await getLatLng(geocodedByAddress[0]);
    setCoordinates(latLng);
  };

  const onSubmit = async () => {
    // if (!restaurant) {
    //   await createNewRestaurant();
    // } else {
    //   await updateRestaurant();
    // }
  };

  const createNewRestaurant = async () => {
    const newRestaurant = await DataStore.save(
      new Restaurant({
        name,
        image:
          "https://notjustdev-dummy.s3.us-east-2.amazonaws.com/uber-eats/restaurant1.jpeg",
        deliveryFee: 0,
        minDeliveryTime: 15,
        maxDeliveryTime: 120,
        adminSub: sub,
      })
    );
    setRestaurant(newRestaurant);
    message.success("El Restaurante ha sido actualizado!");
  };

  const updateRestaurant = async () => {
    const updatedRestaurant = await DataStore.save(
      Restaurant.copyOf(restaurant, (updated) => {
        updated.name = name;
        if (address) {
          updated.address = address.label;
        }
      })
    );
    setRestaurant(updatedRestaurant);
    message.success("El Restaurante ha sido actualizado!");
  };

  return (
    <Card title="Detalles del restaurante" style={{ margin: 20 }}>
      <Form layout="vertical" wrapperCol={{ span: 8 }} onFinish={onSubmit}>
        <Form.Item label="Nombre del restaurant" required>
          <Input
            placeholder="Ingresa el nombre del restaurante"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Horario">
          <TimePicker.RangePicker
            onChange={(_, dateString) => {
              setTimezone(dateString);
            }}
            placeholder={["Abierto", "Cerrado"]}
            format={format}
            required={true}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Settings;
