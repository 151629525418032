// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TransportationModes = {
  "DRIVING": "DRIVING",
  "BICYCLING": "BICYCLING"
};

const OrderStatus = {
  "NUEVO": "NUEVO",
  "EN_PROCESO": "EN_PROCESO",
  "COMPLETADO": "COMPLETADO",
  "RECHAZADO": "RECHAZADO",
  "EN_CAMINO": "EN_CAMINO"
};

const { Courier, Basket, BasketDish, Dish, OrderDish, Order, Restaurant, User, BasketDishDish } = initSchema(schema);

export {
  Courier,
  Basket,
  BasketDish,
  Dish,
  OrderDish,
  Order,
  Restaurant,
  User,
  BasketDishDish,
  TransportationModes,
  OrderStatus
};